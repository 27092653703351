import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';
import 'normalize.css';
import './css-reset.css';
import './index.scss';
import { useSiteMetadata } from '../utils/useSiteMetadata';

const MainLayout: React.FC = ({ children }) => {
  const { description } = useSiteMetadata();
  return (
    <div className="page-layout">
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta name="description" content={description ?? ''} />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
