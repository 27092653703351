import { graphql, useStaticQuery } from 'gatsby';
import { GetSiteMetadataQuery, SiteSiteMetadata } from '../graphqlTypes';

export const useSiteMetadata = (): SiteSiteMetadata => {
  const { site } = useStaticQuery<GetSiteMetadataQuery>(
    graphql`
      query GetSiteMetadata {
        site {
          siteMetadata {
            title
            url
            description
            siteUrl
            owner {
              name
              email
              location
              avatar
              description
              usernames {
                twitter
                github
                linkedIn
              }
            }
          }
        }
      }
    `
  );

  if (!site?.siteMetadata) throw new Error(`Couldn't get site metadata`);

  return site.siteMetadata;
};
